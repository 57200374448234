import { defineStore } from 'pinia';
import { appRouteTo } from '@/router';

// demo api imports

// real api imports
import {
  updateRefFromApi,
  logoutAndRefresh,
  ApiRoute,
} from '@/lib/api-request';

import { ref, computed, Ref } from 'vue';
import { differenceInMinutes } from 'date-fns';
import { UserResponse } from '@shared/users';
import { TagResponse } from '@shared/tags';
import { ContactUsResponse } from '@shared/franchises';
import {
  ReportsController,
  SupportActionsController,
  TagsController,
  UsersController,
} from '@/lib/api-routes';
import { ReportRouteName } from '@shared/reports';

export const useApiStore = defineStore('api', () => {
  // reactive variable to store the current user
  const user = ref<UserResponse | undefined>();
  // reactive variable to store the representee
  const representee = ref<UserResponse | undefined>();
  // reactive variable to store the tags
  const tagOptions = ref<TagResponse[]>([]);

  // server consts and enums, will probably all be fetched in one go.
  const officePhoneNumber = ref('1300 229 877');

  const choiceYesNo = new Map([
    [true, 'Yes'],
    [false, 'No'],
  ]);

  const artistTypes = ref(
    new Map([
      ['balloon_twister', 'Balloon Twister'],
      ['face_painter', 'Face Painter'],
    ]),
  );

  const eventTypeChoices = new Map([
    ['birthday', 'Birthday'],
    ['corporate_event', 'Corporate Event'],
    ['other', 'Other'],
  ]);

  const drivingRatePerKm = ref(1.0);

  const frequencyChoices = new Map([
    [7, 'Weekly'],
    [14, 'Fortnightly'],
  ]);

  const choiceAtHome = new Map(
    [
      'I will be out of the house for the majority of the time',
      'I will be at home for the majority of the time',
    ].entries(),
  );

  const sitterQuestions = [
    ['experience', "What's your experience looking after children?"],
    ['love', 'What do you love about being a babysitter?'],
    ['spareTime', 'What do you like to do in your spare time?'],
    ['secretTalents', 'Do you have any secret talents?'],
    [
      'favouriteActivity',
      "What's your favourite activity to do with the children?",
    ],
    ['superhero', 'If you were a superhero, who would you be and why?'],
  ];

  // indicates when content is being loaded
  const loadingCount = ref(0);

  const isLoading = computed(() => loadingCount.value > 0);

  // indicates that an internet error has occured
  const internetError = ref(false);

  let userLoadingPromise: Promise<boolean> | undefined;
  async function loadUser(force = false) {
    // if a load is currently in progress, just return the promise
    // for the current load because the data will be freshly loaded.
    if (userLoadingPromise) {
      return userLoadingPromise;
    }

    // if the user is already loaded, and we're not forcing a reload, and the user was loaded less than 5 minutes ago, then don't reload.
    if (
      !force &&
      user.value?.loadedAt &&
      differenceInMinutes(Date.now(), user.value.loadedAt) < 5
    ) {
      return true;
    }

    userLoadingPromise = updateRefFromApi(user, UsersController.findMe);
    const result = await userLoadingPromise;
    if (result !== false && user.value?.userType) {
      user.value.loadedAt = new Date();
      localStorage.setItem('userType', user.value.userType);
    }
    userLoadingPromise = undefined;

    return result;
  }

  async function loadAllTags() {
    return await updateRefFromApi(tagOptions, TagsController.findAll);
  }

  async function loadContactUs(pageModel: Ref<ContactUsResponse | undefined>) {
    await updateRefFromApi(pageModel, SupportActionsController.getContactUs);
  }

  async function loadRepresentee() {
    return await updateRefFromApi(representee, UsersController.findRepresentee); // this will load either the current user, or the representee if the user is an admin.
  }

  function getApiTokenOrRouteToLogin() {
    // this will redirect to the login screen if the token is unavailable
    const token = localStorage.getItem('token');
    if (token) {
      return token;
    } else {
      appRouteTo('login').then(() => window.location.reload());
    }
  }

  const reportApiRouteRecords: Record<ReportRouteName, ApiRoute> = {
    business_bookings: ReportsController.fetchBusinessBookings,
    bookings_refunds_cancellations_credits:
      ReportsController.fetchBookingRefundsAndCancellations,
    client_report: ReportsController.fetchClientsReports,
    sitters_jobs: ReportsController.fetchSittersJobs,
    jobs_fill_time: ReportsController.fetchAverageJobsFillTime,
  };

  return {
    officePhoneNumber,
    artistTypes,
    eventTypeChoices,
    frequencyChoices,
    drivingRatePerKm,
    choiceAtHome,
    loadingCount,
    user,
    representee,
    isLoading,
    internetError,
    sitterQuestions,
    choiceYesNo,
    tagOptions,
    reportApiRouteRecords,
    loadAllTags,
    loadUser,
    loadRepresentee,
    getApiTokenOrRouteToLogin,
    loadContactUs,
    logoutAndRefresh, // from api-request.ts
  };
});
