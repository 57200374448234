import { modalController } from '@ionic/vue';
import MyUtilityModal from '@/components/MyUtilityModal.vue';
import { OverlayEventDetail } from '@ionic/core';

export interface ModalButton {
  label: string;
  data?: any;
  role?: string;
  color?: string;
  fill?: string;
}

export interface ModalOptions {
  title: string;
  message: string;
  color?: string;
  buttons?: Array<ModalButton>;
}

export async function showModal(
  modalOptions: ModalOptions,
): Promise<OverlayEventDetail<any>> {
  const defaultButtonColor = modalOptions.color || 'primary';
  if (!modalOptions.buttons) {
    modalOptions.buttons = [{ label: 'OK', color: defaultButtonColor }];
  }

  const modal = await modalController.create({
    component: MyUtilityModal,
    componentProps: { modalOptions },
    cssClass: 'my-utility-modal',
  });
  modal.present();
  return modal.onDidDismiss();
}
