//THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
//To update this file, run 'npx nestjs-command api-routes:generate'
//from the server project directory.

import type { ApiRoute } from "./api-request";
class AppControllerClass {
public getHello: ApiRoute = {"method":"GET","path":""};
}
class XeroWebhooksControllerClass {
public create: ApiRoute = {"method":"POST","path":"xero-webhooks"};
}
class XeroPaymentsControllerClass {
public getConsentUrl: ApiRoute = {"method":"GET","path":"xero-payments/consent-url"};
public authCallback: ApiRoute = {"method":"GET","path":"xero-payments/auth-callback"};
}
class UsersControllerClass {
public create: ApiRoute = {"method":"POST","path":"users"};
public signIn: ApiRoute = {"method":"POST","path":"users/login"};
public signOut: ApiRoute = {"method":"POST","path":"users/logout"};
public forgotPassword: ApiRoute = {"method":"POST","path":"users/forgot_password"};
public resetPassword: ApiRoute = {"method":"POST","path":"users/reset_password"};
public findMe: ApiRoute = {"method":"GET","path":"users"};
public findRepresentee: ApiRoute = {"method":"GET","path":"users/representee"};
public findOne: ApiRoute = {"method":"GET","path":"users/:id"};
public updatePassword: ApiRoute = {"method":"PATCH","path":"users/change_password"};
public updateMessagingToken: ApiRoute = {"method":"PATCH","path":"users/messaging_token"};
}
class TagsControllerClass {
public create: ApiRoute = {"method":"POST","path":"tags"};
public update: ApiRoute = {"method":"PATCH","path":"tags/:id"};
public findAll: ApiRoute = {"method":"GET","path":"tags"};
public remove: ApiRoute = {"method":"DELETE","path":"tags/:id"};
}
class SupportActionsControllerClass {
public getAdminMail: ApiRoute = {"method":"GET","path":"support-actions/admin-mail"};
public getFeedbackLinks: ApiRoute = {"method":"GET","path":"support-actions/feedback-links"};
public sendMessage: ApiRoute = {"method":"POST","path":"support-actions/send-message"};
public sendSitterFeedback: ApiRoute = {"method":"POST","path":"support-actions/send-sitter-feedback"};
public sendFeedback: ApiRoute = {"method":"POST","path":"support-actions/send-feedback"};
public addFaq: ApiRoute = {"method":"PATCH","path":"support-actions/update-faqs"};
public getAllFaqs: ApiRoute = {"method":"GET","path":"support-actions/faqs"};
public getSitterFaqs: ApiRoute = {"method":"GET","path":"support-actions/faqs/sitter"};
public getCustomerFaqs: ApiRoute = {"method":"GET","path":"support-actions/faqs/customer"};
public findOneFaq: ApiRoute = {"method":"GET","path":"support-actions/faqs/:id"};
public deleteFAQ: ApiRoute = {"method":"DELETE","path":"support-actions/faqs/:id"};
public getInfoPages: ApiRoute = {"method":"GET","path":"support-actions/infopages/:tag"};
public updateInfoPage: ApiRoute = {"method":"PATCH","path":"support-actions/infopages"};
public getContactUs: ApiRoute = {"method":"GET","path":"support-actions/contact-us"};
public updateContactUs: ApiRoute = {"method":"PATCH","path":"support-actions/contact-us"};
}
class StripeWebhooksControllerClass {
public webHook: ApiRoute = {"method":"POST","path":"stripe-webhooks"};
}
class StripePaymentsControllerClass {
public publishableKey: ApiRoute = {"method":"GET","path":"stripe-payments/publishable-key"};
public create: ApiRoute = {"method":"POST","path":"stripe-payments"};
public findOne: ApiRoute = {"method":"GET","path":"stripe-payments/:intentId"};
public remove: ApiRoute = {"method":"DELETE","path":"stripe-payments/:intentId"};
}
class StoredFilesControllerClass {
public findOne: ApiRoute = {"method":"GET","path":"stored-files/:id"};
}
class SittersControllerClass {
public getSitterPicture: ApiRoute = {"method":"GET","path":"sitters/picture"};
public create: ApiRoute = {"method":"POST","path":"sitters"};
public findMe: ApiRoute = {"method":"GET","path":"sitters/me"};
public getStripeAccountStatus: ApiRoute = {"method":"GET","path":"sitters/stripe-account-status"};
public createStripeAccountLink: ApiRoute = {"method":"POST","path":"sitters/stripe-account-link"};
public findAll: ApiRoute = {"method":"GET","path":"sitters"};
public update: ApiRoute = {"method":"PATCH","path":"sitters"};
public getSitterStatusLog: ApiRoute = {"method":"GET","path":"sitters/status-log"};
public autoComplete: ApiRoute = {"method":"GET","path":"sitters/auto-complete"};
public updateCategory: ApiRoute = {"method":"PATCH","path":"sitters/category"};
public updateStatus: ApiRoute = {"method":"PATCH","path":"sitters/:id/status"};
public updateQuestions: ApiRoute = {"method":"PATCH","path":"sitters/questions"};
public uploadFile: ApiRoute = {"method":"POST","path":"sitters/upload"};
public updateUploadFile: ApiRoute = {"method":"PATCH","path":"sitters/update-upload"};
public findBlacklistedBy: ApiRoute = {"method":"GET","path":"sitters/blacklisted-by"};
public findSitterDocuments: ApiRoute = {"method":"GET","path":"sitters/documents"};
public findOne: ApiRoute = {"method":"GET","path":"sitters/:id"};
public findOneByUserId: ApiRoute = {"method":"GET","path":"sitters/:userId/by-user-id"};
public updateSitterTags: ApiRoute = {"method":"PATCH","path":"sitters/tags"};
public updateSitterAge: ApiRoute = {"method":"PATCH","path":"sitters/minAge"};
}
class SitterPaymentsControllerClass {
public getPreauthFailedBookingIds: ApiRoute = {"method":"GET","path":"sitter-payments/preauth-failed"};
public completeJobWithPricing: ApiRoute = {"method":"PATCH","path":"sitter-payments/complete"};
}
class SendEmailsControllerClass {
public showEmailContent: ApiRoute = {"method":"GET","path":"send-emails"};
}
class ResourcesControllerClass {
public create: ApiRoute = {"method":"POST","path":"resources"};
public findAll: ApiRoute = {"method":"GET","path":"resources"};
public findOne: ApiRoute = {"method":"GET","path":"resources/:id"};
public update: ApiRoute = {"method":"PATCH","path":"resources/:id"};
public remove: ApiRoute = {"method":"DELETE","path":"resources/:id"};
}
class ReportsControllerClass {
public fetchBusinessBookings: ApiRoute = {"method":"GET","path":"reports/business_bookings"};
public fetchBookingRefundsAndCancellations: ApiRoute = {"method":"GET","path":"reports/bookings_refunds_cancellations_credits"};
public fetchClientsReports: ApiRoute = {"method":"GET","path":"reports/client_report"};
public fetchSittersJobs: ApiRoute = {"method":"GET","path":"reports/sitters_jobs"};
public fetchAverageJobsFillTime: ApiRoute = {"method":"GET","path":"reports/jobs_fill_time"};
}
class PricingControllerClass {
public getOwnCarCharges: ApiRoute = {"method":"GET","path":"pricing/own-car-charges"};
}
class PaymentsControllerClass {
public findAll: ApiRoute = {"method":"GET","path":"payments"};
}
class PartyBookingsControllerClass {
public findAll: ApiRoute = {"method":"GET","path":"party-bookings"};
public pricing: ApiRoute = {"method":"GET","path":"party-bookings/pricing"};
public validateBooking: ApiRoute = {"method":"POST","path":"party-bookings/pricing"};
public create: ApiRoute = {"method":"POST","path":"party-bookings"};
public update: ApiRoute = {"method":"PATCH","path":"party-bookings/:id"};
public publish: ApiRoute = {"method":"POST","path":"party-bookings/:id/publish"};
}
class ParentsControllerClass {
public createAsUser: ApiRoute = {"method":"POST","path":"parents/as-user"};
public create: ApiRoute = {"method":"POST","path":"parents"};
public searchByName: ApiRoute = {"method":"GET","path":"parents/search"};
public findAll: ApiRoute = {"method":"GET","path":"parents"};
public findOne: ApiRoute = {"method":"GET","path":"parents/:id"};
public updateSitterBlacklist: ApiRoute = {"method":"PATCH","path":"parents/blacklist-sitter"};
public updateAsCustomer: ApiRoute = {"method":"PATCH","path":"parents"};
public update: ApiRoute = {"method":"PATCH","path":"parents/:id"};
public updateParentInputFields: ApiRoute = {"method":"PATCH","path":"parents/:id/input-fields"};
public updateParentTags: ApiRoute = {"method":"PATCH","path":"parents/:id/tags"};
public remove: ApiRoute = {"method":"DELETE","path":"parents/:id"};
public createChild: ApiRoute = {"method":"POST","path":"parents/:id/children"};
public updateChild: ApiRoute = {"method":"PATCH","path":"parents/:id/children/:cid"};
public deleteChild: ApiRoute = {"method":"DELETE","path":"parents/:id/children/:cid"};
}
class OngoingBookingsControllerClass {
public findAll: ApiRoute = {"method":"GET","path":"ongoing-bookings"};
public get: ApiRoute = {"method":"GET","path":"ongoing-bookings/:id"};
public publish: ApiRoute = {"method":"POST","path":"ongoing-bookings/publish/:id/:sitterId"};
public create: ApiRoute = {"method":"POST","path":"ongoing-bookings"};
public getPricing: ApiRoute = {"method":"GET","path":"ongoing-bookings/pricing"};
public pricing: ApiRoute = {"method":"POST","path":"ongoing-bookings/pricing"};
public update: ApiRoute = {"method":"PATCH","path":"ongoing-bookings/:id"};
}
class NotificationsControllerClass {
public test: ApiRoute = {"method":"GET","path":"notifications/test"};
public create: ApiRoute = {"method":"POST","path":"notifications"};
public findAll: ApiRoute = {"method":"GET","path":"notifications"};
public update: ApiRoute = {"method":"PATCH","path":"notifications/:id"};
}
class EventBookingsControllerClass {
public create: ApiRoute = {"method":"POST","path":"event-bookings"};
public pricing: ApiRoute = {"method":"POST","path":"event-bookings/pricing"};
public findAll: ApiRoute = {"method":"GET","path":"event-bookings"};
public update: ApiRoute = {"method":"PATCH","path":"event-bookings/:id"};
public publish: ApiRoute = {"method":"POST","path":"event-bookings/publish"};
}
class CustomersControllerClass {
public create: ApiRoute = {"method":"POST","path":"customers"};
public findAll: ApiRoute = {"method":"GET","path":"customers"};
public update: ApiRoute = {"method":"PATCH","path":"customers"};
public getPreviousSitters: ApiRoute = {"method":"GET","path":"customers/previous-sitters"};
public getFavouriteSitters: ApiRoute = {"method":"GET","path":"customers/favourite-sitters"};
public likeSitter: ApiRoute = {"method":"PATCH","path":"customers/like-sitter"};
public findOneByUserId: ApiRoute = {"method":"GET","path":"customers/:userId/by-user-id"};
}
class CasualBookingsControllerClass {
public pricing: ApiRoute = {"method":"POST","path":"casual-bookings/pricing"};
public create: ApiRoute = {"method":"POST","path":"casual-bookings"};
public findAll: ApiRoute = {"method":"GET","path":"casual-bookings"};
}
class BookingsControllerClass {
public findAll: ApiRoute = {"method":"GET","path":"bookings"};
public findAttentionRequired: ApiRoute = {"method":"GET","path":"bookings/attention-required"};
public sitterAcceptOrDeclineUpdate: ApiRoute = {"method":"POST","path":"bookings/update-sitter-response"};
public customerAcceptSitterUpdate: ApiRoute = {"method":"POST","path":"bookings/customer-mark-update-accepted/:id"};
public handleDeclinedUpdateAction: ApiRoute = {"method":"POST","path":"bookings/declined-update/:id/:action"};
public cancelBooking: ApiRoute = {"method":"PATCH","path":"bookings/cancel"};
public updateTravelFee: ApiRoute = {"method":"PATCH","path":"bookings/travel-fee"};
public findOne: ApiRoute = {"method":"GET","path":"bookings/:id"};
public updateNotes: ApiRoute = {"method":"PATCH","path":"bookings/:id/notes"};
public sitterSwap: ApiRoute = {"method":"PATCH","path":"bookings/:id/sitter-swap"};
public update: ApiRoute = {"method":"PATCH","path":"bookings/:id"};
public getBookingStatusLog: ApiRoute = {"method":"GET","path":"bookings/:id/status-log"};
public advanceBookingStage: ApiRoute = {"method":"PATCH","path":"bookings/:id/advance"};
}
class BookingCreditsControllerClass {
public clearBooking: ApiRoute = {"method":"GET","path":"booking-credits/clear-booking"};
public checkPayment: ApiRoute = {"method":"PATCH","path":"booking-credits/:bookingId/check-payment"};
}
class AdvertisementsControllerClass {
public findAllSitterBookings: ApiRoute = {"method":"GET","path":"advertisements/bookings"};
public findOne: ApiRoute = {"method":"GET","path":"advertisements/:bookingId"};
public findAll: ApiRoute = {"method":"GET","path":"advertisements"};
public message: ApiRoute = {"method":"POST","path":"advertisements/message"};
public create: ApiRoute = {"method":"POST","path":"advertisements"};
public offerAlternateTimes: ApiRoute = {"method":"PATCH","path":"advertisements/offer-alternate-times"};
public acceptAlternateTimes: ApiRoute = {"method":"PATCH","path":"advertisements/accept-alternate-times"};
public update: ApiRoute = {"method":"PATCH","path":"advertisements/:bookingId"};
}
class AdminGroupsControllerClass {
public listGroups: ApiRoute = {"method":"GET","path":"admin-groups/choices"};
public findAll: ApiRoute = {"method":"GET","path":"admin-groups"};
public create: ApiRoute = {"method":"POST","path":"admin-groups"};
public update: ApiRoute = {"method":"PATCH","path":"admin-groups/:userId"};
public remove: ApiRoute = {"method":"DELETE","path":"admin-groups/:userId"};
}
export const AppController = new AppControllerClass();
export const XeroWebhooksController = new XeroWebhooksControllerClass();
export const XeroPaymentsController = new XeroPaymentsControllerClass();
export const UsersController = new UsersControllerClass();
export const TagsController = new TagsControllerClass();
export const SupportActionsController = new SupportActionsControllerClass();
export const StripeWebhooksController = new StripeWebhooksControllerClass();
export const StripePaymentsController = new StripePaymentsControllerClass();
export const StoredFilesController = new StoredFilesControllerClass();
export const SittersController = new SittersControllerClass();
export const SitterPaymentsController = new SitterPaymentsControllerClass();
export const SendEmailsController = new SendEmailsControllerClass();
export const ResourcesController = new ResourcesControllerClass();
export const ReportsController = new ReportsControllerClass();
export const PricingController = new PricingControllerClass();
export const PaymentsController = new PaymentsControllerClass();
export const PartyBookingsController = new PartyBookingsControllerClass();
export const ParentsController = new ParentsControllerClass();
export const OngoingBookingsController = new OngoingBookingsControllerClass();
export const NotificationsController = new NotificationsControllerClass();
export const EventBookingsController = new EventBookingsControllerClass();
export const CustomersController = new CustomersControllerClass();
export const CasualBookingsController = new CasualBookingsControllerClass();
export const BookingsController = new BookingsControllerClass();
export const BookingCreditsController = new BookingCreditsControllerClass();
export const AdvertisementsController = new AdvertisementsControllerClass();
export const AdminGroupsController = new AdminGroupsControllerClass();
