export const bookingFeePaymentTypes = new Map([
  ['StripePayment', 'Stripe'],
  ['XeroInvoice', 'Xero'],
  ['Manual', 'Manual Payment Method'],
  ['Pending', 'Payment Type Pending'],
]);

/*
export const bookingStatuses = [
  'manualInterventionRequired',
  'unpaid',
  'advertised',
  //'applied', // not used
  'issued',
  'updated',
  'updateDeclined',
  'completed',
  'cancelled',
] as const;
export type BookingStatus = (typeof bookingStatuses)[number];
*/

export interface PlacesApiAddress {
  place_id: string;
  formatted_address: string;
  address_components: Array<{
    long_name: string;
    short_name: string;
    types: Array<string>;
  }>;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
    viewport: {
      south: number;
      west: number;
      north: number;
      east: number;
    };
  };
}

/*
export class Address {
  unitNumber: string;
  streetNumber: string;
  street: string;
  city: string;
  state: string;
  postcode: string;

  latitude?: number;
  longitude?: number;

  placeId?: string;

  constructor(placesAddress: google.maps.places.PlaceResult) {
    const unitNumber = placesAddress.address_components?.find((component) =>
      component.types.includes('subpremise'),
    )?.short_name;
    const streetNumber = placesAddress.address_components?.find((component) =>
      component.types.includes('street_number'),
    )?.short_name;
    const street = placesAddress.address_components?.find((component) =>
      component.types.includes('route'),
    )?.short_name;

    this.placeId = placesAddress.place_id;
    this.unitNumber = unitNumber ?? '';
    this.streetNumber = streetNumber ?? '';
    this.street = street ?? '';
    this.city =
      placesAddress.address_components?.find((component) =>
        component.types.includes('locality'),
      )?.short_name ?? '';
    this.state =
      placesAddress.address_components?.find((component) =>
        component.types.includes('administrative_area_level_1'),
      )?.short_name ?? '';
    this.postcode =
      placesAddress.address_components?.find((component) =>
        component.types.includes('postal_code'),
      )?.short_name ?? '';
    this.latitude = placesAddress.geometry?.location?.lat();
    this.longitude = placesAddress.geometry?.location?.lng();
  }
}
*/

/* export interface Booking extends Updatable {
  _id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  isDeleted: boolean;
  parent: ParentResponse;
  parkingDetails: string;
  interval: { start: string; end: string };
  jobLocationInterval: {
    startTime: string;
    endTime: string;
    endDate: string;
    startDate: string;
    timeZone: string;
  };
  children: string[];
  address: AddressResponse;
  addressNotes: string;
  favouritesFirst: boolean;
  requestedSitters: string[];
  requestedSitterName: string;
  allowFallbackSitters: boolean;
  vehicleType: string;
  drivingDetails: string;
  reason: string;
  otherDetails: string;
  status: BookingStatus;
  bookingCredit?: BookingCredit;
  adminNotes?: string;
  bookingType: BookingType;
  sitter: SitterResponse | null;

  // this field is added by the controller for bookings
  // that are unpaid Xero Invoices
  // in the bookingsRequireAttention field.
  invoiceUrl?: string;

  // update fields
  updatedAddress?: AddressResponse;
  updatedAddressNotes?: string;
  updatedChildren: string[];
  updatedInterval?: { start: string; end: string };
  updatedJobLocationInterval?: {
    startTime: string;
    endTime: string;
    endDate: string;
    startDate: string;
    timeZone: string;
  };
  updatedDistanceText?: string;
  updatedDistanceValue?: number;
  updateReason?: string;
  updateDeclinedNote?: string;
} */

/* MOVED TO SERVER SHARED FOLDER @shared/pricing

// this is a copy of the server/src/bookings/dto/price-booking-date.dto.ts
// dto class.  It is used to return the price of a single booking date.
export interface PriceBookingDateDto {
  hourlyPricing: {
    total: string; // the total price for the block (quantity times rate)
    sitterTotal: string; // the total sitter payment for the block (quantity times rate)
    quantity: number; // the number of units (usually in hours or each)
    rate: string; // the price per unit
    sitterRate: string; // the sitter payment per unit
    unit: string; // the unit of measurement (usually 'hrs' or 'ea')
    title: string; // the name of the block ie. "weekday" or "overnight discount"
  }[];
  sitterPaymentTotal: string; // the total sitter payment for hourly labour in the booking (all hourly blocks added up)
  bookingTotal: string; // the total price for hourly labour in the booking (all hourly blocks added up (excluding booking fee))
  bookingFee: string; // the booking fee for the booking
  bookingFeePaymentType: 'StripePayment' | 'XeroInvoice' | 'Manual' | 'Pending'; // the payment type for the booking fee
  hourlyRatePaymentType: 'XeroInvoice' | 'Direct'; // the payment type for the hourly rate
  accountingCode: string; // this code is used if we create an invoice in Xero
  bookingClass: 'ndis' | 'notForProfit' | 'company' | 'standard';
  bookingCreditApplied: string; // if a booking credit is applied to this booking,
  // the Id of the booking credit is stored here. (And the booking fee is reduced to zero.)
  // It will be an empty string if no credit is applied.
  lateCancellationDeadline: Date; // the date and time after which a late cancellation fee will be charged
  minimumCharge: string;
  earlyCancellationCharge: string;
  lateCancellationCharge: string;
  lateCancellationSitterPayment: string;
  ownCarCharge: string;
}
*/

// a booking as it is returned with the pricing.
/* Represents an ongoing booking, as 
  retrieved from the server
*/

export interface Payment {
  id: string;
  date: string;
  url: string;
  amount: string;
  status: 'paid' | 'unpaid';
  paymentType: 'XeroInvoice' | 'StripePayment';
}
