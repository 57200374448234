import {
  HttpRequestOptions,
  HttpResponse,
  httpRequest,
  statusOk,
} from './http-request';
import { useApiStore } from '@/store';
import { Ref, ref } from 'vue';
import { appRouteTo, router } from '@/router';
import { toOrdinalString } from './to-ordinal-string';
import {
  EventSourceMessage,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import { BookingResponse } from '@shared/bookings';
import { BookingResponse as AdvertisedBookingResponse } from '@shared/advertisements';
import { Paginated } from '@shared/util';
import { showModal } from '@/lib/modal';
import { OngoingBookingResponse } from '@shared/ongoing-bookings';
import { EventBookingResponse } from '@shared/event-bookings';
import { PartyBookingResponse } from '@shared/party-bookings';
import {
  AdvertisementsController,
  BookingsController,
  EventBookingsController,
  OngoingBookingsController,
  PartyBookingsController,
  StoredFilesController,
  UsersController,
} from './api-routes';

export type ApiRequestOptions = HttpRequestOptions & {
  showErrorModal?: boolean;
  routeParams?: Record<string, string | number | undefined>;
};

// a route definition
export type ApiRoute = {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  path: string;
};

// API prefix from ENV file
export const API_PREFIX =
  process.env.VUE_APP_API_PREFIX || 'http://localhost:3000/api/';

// a wrapper to set the loading flag, load the auth token, and redirect to login page on unauthorized response.
export async function apiRequest(
  route: ApiRoute,
  options: ApiRequestOptions = {},
): Promise<HttpResponse> {
  const apiStore = useApiStore();

  // if the representee query parameter is set
  // on the route, pass it to the server
  if (router.currentRoute.value.query.representee) {
    options.queryParams = {
      ...options.queryParams,
      representee: router.currentRoute.value.query.representee,
    };
  }

  const optionsWithDefaults = Object.assign({ routeParams: {} }, options);

  const method = route.method;
  let endpoint = route.path;
  if (!endpoint) {
    console.error('No endpoint specified in route:', route);
    console.trace();
  }

  // check that all supplied route params are needed
  for (const key in optionsWithDefaults.routeParams) {
    if (!endpoint.includes(`:${key}`)) {
      throw new Error(
        `Route param ${key} is not specified in the route string: ${endpoint}`,
      );
    }
  }
  // also check that all of the route params in the route string are present
  // in the options.routeParams object
  const routeParams = endpoint.match(/:\w+\??/g) || [];
  for (const routeParam of routeParams) {
    const key = routeParam.replace(':', '').replace('?', '');
    if (!optionsWithDefaults.routeParams[key]) {
      // if the parameter is not optional..
      if (!routeParam.endsWith('?')) {
        throw new Error(
          `Route param ${key} is not specified in the routeParams object.`,
        );
      } else {
        // remove the optional route param from the route string
        endpoint = endpoint.replace('/' + routeParam, '');
      }
    } else {
      // insert the route param into the route string
      endpoint = endpoint.replace(
        routeParam,
        optionsWithDefaults.routeParams[key]?.toString() ?? '',
      );
    }
  }

  let result;
  try {
    apiStore.loadingCount += 1;

    // load the auth token from local storage
    const accessToken = localStorage.getItem('token');
    result = await httpRequest(method, `${API_PREFIX}${endpoint}`, {
      authToken: accessToken,
      ...options,
    });
  } finally {
    setTimeout(() => (apiStore.loadingCount -= 1), 1000);
  }

  if (result.status == 401) {
    // check the auth status of the current route, if it is
    // 'always' then logout and refresh
    if (router.currentRoute.value.meta.auth == 'always') {
      logoutAndRefresh();
    }
  } else if (result.status == 0) {
    console.error(
      `Showing Internet Connection Error modal for status ${result.status} request to: ${endpoint}`,
    );
    // no response, so assume internet connection error
    apiStore.internetError = true;
  }

  if (options.showErrorModal && !statusOk(result.status)) {
    //show an error modal
    await showModal({
      title: 'Error',
      message:
        typeof result.body.message == 'string'
          ? result.body.message
          : `Error code ${result.status}, please contact support.`,
      color: 'danger',
    });
  }

  return result;
}

export async function logoutAndRefresh() {
  // tell the server that we are logging out
  // (this allows some housework like clearing
  // the registered messaging token)
  try {
    await apiRequest(UsersController.signOut);
  } catch (e) {
    console.error('Error logging out');
    console.error(e);
  }
  // delete the token from local storage
  localStorage.removeItem('token');
  localStorage.removeItem('userType');
  appRouteTo('login').then(() => window.location.reload());
}

export function removeValidationErrors(model: Record<string, any>) {
  if (model && typeof model == 'object' && '_validationErrors' in model) {
    delete model._validationErrors;
  }
  for (const key in model) {
    if (typeof model[key] === 'object') {
      removeValidationErrors(model[key]);
    }
  }
}

export async function submitAndValidate(
  model: Record<string, any>,
  route: ApiRoute,
  options: ApiRequestOptions = {},
): Promise<false | Record<string, any>> {
  // iterate top level object, and any sub-objects and remove the validation errors from the model

  removeValidationErrors(model);

  try {
    const result = await apiRequest(route, { ...options, bodyParams: model });

    // clear any old general errors if they exist.
    if ('_generalError' in model) {
      delete model._generalError;
    }

    if (result.status == 400 && 'message' in result.body) {
      // 400 is validation error.

      // recursively populate validation errors
      const placeValidationErrors = (
        modelPart: Record<string, any>,
        response: Record<string, any>[],
      ) => {
        // make sure modelPart has a _validationErrors property
        if (!('_validationErrors' in modelPart)) {
          modelPart._validationErrors = {};
        }

        // libraries like stripe, sometimes throw their own
        // validation errors.  This is an attempt to catch them
        // in a somewhat orgranised fasion:
        if (typeof response === 'string') {
          model._generalError = response;
        } else if (response && typeof response === 'object') {
          for (const errorProperty of response) {
            if (errorProperty.constraints) {
              // assign the first property of message.constraints to the model _validaionErrors
              modelPart._validationErrors[errorProperty.property] =
                Object.values(errorProperty.constraints)[0];
            }
            if ((errorProperty.children?.length || 0) > 0) {
              // check if errorProperty.property is fully numeric
              let propertyWords = 'Unknown';
              if (/^\d+$/.test(errorProperty.property)) {
                // if so, the use the index +1 as the ordinal name of the field
                propertyWords = toOrdinalString(
                  parseInt(errorProperty.property) + 1,
                );
              } else {
                // convert erroProperty.property from camel case to separate title case words
                propertyWords = errorProperty.property
                  .replace(/([A-Z])/g, ' $1')
                  .replace(/^./, (str: string) => str.toUpperCase());
              }
              modelPart._validationErrors[errorProperty.property] =
                `There is a problem with the ${propertyWords} field.`;
              if (errorProperty.property in modelPart) {
                placeValidationErrors(
                  modelPart[errorProperty.property],
                  errorProperty.children,
                );
              }
            }
          }
        }
      };

      placeValidationErrors(model, result.body.message);
      return false;
    }

    if (statusOk(result.status)) {
      // if the request was successful and the body has an 'access_token' property, then store (or update) the token in local storage.
      if (
        result.body.data &&
        typeof result.body.data === 'object' &&
        'access_token' in result.body.data
      ) {
        localStorage.setItem('token', result.body.data.access_token);
      }
      return result.body;
    } else {
      model._generalError = `Error code ${
        result.status
      } please contact support. (Message: ${
        result.body?.message || 'No message'
      })`;
    }
  } catch (e: any) {
    console.error('Error in request');
    console.error(e);
    let message = 'No Message';
    if ('message' in e || {}) {
      message = e.message;
    }

    model._generalError = `App error, please contact support. (Message: ${message})`;

    return false;
  }

  return false;
}

export async function updateRefFromApi<T>(
  ref: Ref<T | undefined> | Ref<T>,
  route: ApiRoute,
  options?: ApiRequestOptions & {
    pagination?: { startingRecord: number; recordsPerPage: number };
  },
): Promise<boolean> {
  if (options?.pagination) {
    options.queryParams = {
      ...options.queryParams,
      ...options.pagination,
    };
  }

  const result = await apiRequest(route, options);
  if (statusOk(result.status)) {
    if (options?.pagination) {
      ref.value = result.body;
    } else {
      ref.value = result.body.data;
    }
    return true;
  }
  return false;
}

export type DeleteResourceResult = {
  isOk: boolean;
  responseBody: any;
};

export async function deleteResource(
  route: ApiRoute,
  routeParams: Record<string, string | number>,
): Promise<DeleteResourceResult> {
  const result = await apiRequest(route, { routeParams });
  return { isOk: statusOk(result.status), responseBody: result.body };
}

// ################################
// Connect to eventsource endpoints
// ################################
export async function connectToEventSource(
  endpoint: string,
  handler: (message: EventSourceMessage) => void,
) {
  const url = `${API_PREFIX}${endpoint}`;
  let headers;
  if (localStorage.getItem('token')) {
    headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
  }

  const ctrl = new AbortController();

  await fetchEventSource(url, {
    headers,
    onmessage: handler,
    signal: ctrl.signal,
  });

  return ctrl;
}

// ########################################################
// Some shared utility functions to load specific resources
// ########################################################
export type FetchBookingOptions = {
  status?: string;
  type?: string;
  artistTypes?: string[];
  startDate?: Date;
  endDate?: Date;
  includePricing?: boolean;
  silentLoad?: boolean;
  search?: string;
};

export type FetchEventBookingOptions = {
  status?: string;
  type?: string[];
  startDate?: Date;
  endDate?: Date;
  includePricing?: boolean;
  silentLoad?: boolean;
  search?: string;
};

export async function fetchBookings(
  result: Ref<Paginated<BookingResponse>>,
  startingRecord = 0,
  options?: FetchBookingOptions,
) {
  const queryParams: Record<string, string | number> = {};
  if (options) {
    const {
      status,
      type,
      search,
      startDate,
      endDate,
      includePricing,
      silentLoad,
    } = options;
    if (status) {
      queryParams.status = status;
    }
    if (type) {
      queryParams.type = type;
    }
    if (search) {
      queryParams.search = search;
    }

    if (startDate) {
      queryParams.start_date = startDate.toISOString();
    }

    if (endDate) {
      queryParams.end_date = endDate.toISOString();
    }

    if (includePricing) {
      queryParams.pricing = 1;
    }
    // empty the bookings array to indicate that we are loading
    if (!silentLoad) {
      result.value.data = [];
    }
  }

  const pagination = {
    startingRecord,
    recordsPerPage: result.value.recordsPerPage || 10,
  };

  return await updateRefFromApi(result, BookingsController.findAll, {
    queryParams,
    pagination,
  });
}

export async function fetchSitterBookings(
  result: Ref<Paginated<AdvertisedBookingResponse>>,
  startingRecord = 0,
  options?: FetchBookingOptions,
) {
  const queryParams: Record<string, string | number> = {};
  if (options) {
    const { status, type, startDate, endDate, silentLoad } = options;
    if (status) {
      queryParams.status = status;
    }
    if (type) {
      queryParams.type = type;
    }

    if (startDate) {
      queryParams.start_date = startDate.toISOString();
    }

    if (endDate) {
      queryParams.end_date = endDate.toISOString();
    }

    // empty the bookings array to indicate that we are loading
    if (!silentLoad) {
      result.value.data = [];
    }
  }

  const pagination = {
    startingRecord,
    recordsPerPage: result.value.recordsPerPage || 10,
  };

  return await updateRefFromApi(
    result,
    AdvertisementsController.findAllSitterBookings,
    {
      queryParams,
      pagination,
    },
  );
}

export async function fetchOngoingBookings(
  result: Ref<Paginated<OngoingBookingResponse>>,
  startingRecord = 0,
  options?: FetchBookingOptions,
) {
  const queryParams: Record<string, string | number> = {};
  if (options) {
    const { search, startDate, endDate, status } = options;
    if (search) {
      queryParams.search = search;
    }

    if (startDate && endDate) {
      queryParams.start_date = startDate.toISOString();
      queryParams.end_date = endDate.toISOString();
    }

    if (status) {
      queryParams.status = status;
    }
  }

  const pagination = {
    startingRecord,
    recordsPerPage: result.value.recordsPerPage || 10,
  };

  // empty the bookings array to indicate that we are loading
  if (!options?.silentLoad) {
    result.value.data = [];
  }

  return await updateRefFromApi(result, OngoingBookingsController.findAll, {
    queryParams,
    pagination,
  });
}

export async function fetchPartyBookings(
  result: Ref<Paginated<PartyBookingResponse>>,
  startingRecord = 0,
  options?: FetchBookingOptions,
) {
  const queryParams: Record<string, string | number> = {};
  if (options) {
    const { search, startDate, endDate, type, silentLoad, artistTypes } =
      options;
    if (search) {
      queryParams.search = search;
    }
    if (startDate) {
      queryParams.start_date = startDate.toISOString();
    }
    if (endDate) {
      queryParams.end_date = endDate.toISOString();
    }
    if (type) {
      queryParams.type = type;
    }

    if (artistTypes) {
      queryParams.artist_types = artistTypes.join(',');
    }

    if (!silentLoad) {
      result.value.data = [];
    }
  }

  const pagination = {
    startingRecord,
    recordsPerPage: result.value.recordsPerPage || 10,
  };

  console.log(JSON.stringify(queryParams, null, 2));

  return await updateRefFromApi(result, PartyBookingsController.findAll, {
    queryParams,
    pagination,
  });
}

export async function fetchOneBooking(
  result: Ref<BookingResponse | undefined>,
  id: string,
) {
  // I'm using the findAll() server route with an _id param to get the booking in a
  // fully populated format.

  const paginatedBooking = ref<Paginated<BookingResponse>>({
    data: [],
    recordsPerPage: 10,
    totalRecords: 0,
    startingRecord: 0,
  });
  const response = await updateRefFromApi(
    paginatedBooking,
    BookingsController.findAll,
    {
      queryParams: {
        _id: id,
        pricing: 1,
      },
      pagination: {
        startingRecord: 0,
        recordsPerPage: 1,
      },
    },
  );

  if (response) {
    result.value = paginatedBooking.value.data[0];
  }

  return response;
}

export async function fetchOneOngoingBooking(
  result: Ref<OngoingBookingResponse | undefined>,
  id: string,
) {
  // I'm using the findAll() server route with an _id param to get the booking in a
  // fully populated format.

  const paginatedBooking = ref<Paginated<OngoingBookingResponse>>({
    data: [],
    recordsPerPage: 10,
    totalRecords: 0,
    startingRecord: 0,
  });
  return updateRefFromApi(paginatedBooking, OngoingBookingsController.findAll, {
    queryParams: {
      _id: id,
    },
    pagination: {
      startingRecord: 0,
      recordsPerPage: 1,
    },
  }).then(() => {
    result.value = paginatedBooking.value.data[0];
  });
}

export async function fetchOneEventBooking(
  result: Ref<EventBookingResponse | undefined>,
  id: string,
) {
  // I'm using the findAll() server route with an _id param to get the booking in a
  // fully populated format.

  const paginatedBooking = ref<Paginated<EventBookingResponse>>({
    data: [],
    recordsPerPage: 10,
    totalRecords: 0,
    startingRecord: 0,
  });
  return updateRefFromApi(paginatedBooking, EventBookingsController.findAll, {
    queryParams: {
      _id: id,
    },
    pagination: {
      startingRecord: 0,
      recordsPerPage: 1,
    },
  }).then(() => {
    result.value = paginatedBooking.value.data[0];
  });
}

export async function fetchOnePartyBooking(
  result: Ref<PartyBookingResponse | undefined>,
  id: string,
) {
  // I'm using the findAll() server route with an _id param to get the booking in a
  // fully populated format.

  const paginatedBooking = ref<Paginated<PartyBookingResponse>>({
    data: [],
    recordsPerPage: 10,
    totalRecords: 0,
    startingRecord: 0,
  });
  return updateRefFromApi(paginatedBooking, PartyBookingsController.findAll, {
    queryParams: {
      _id: id,
    },
    pagination: {
      startingRecord: 0,
      recordsPerPage: 1,
    },
  }).then(() => {
    result.value = paginatedBooking.value.data[0];
  });
}

export async function updateDataUrl(ref: Ref<string>, fileId?: string) {
  if (fileId) {
    const result = await apiRequest(StoredFilesController.findOne, {
      routeParams: { id: fileId },
    });
    if (result.status == 200) {
      ref.value = result.body.toString();
      return true;
    }
  }
  return false;
}
